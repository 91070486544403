<template>
  <div class="articlelist">
    <div class="common-header-wrap">
      <div>
        <img class="header-img" :src="require('../../assets/images/common-banner.jpg')" alt="">
      </div>
      <div class="tab-list dis-flex" v-if="menus && menus.children && menus.children.length > 0">
        <div class="tab-item flex-shrink0 dis-flex flex-x-center flex-y-center"
          v-for="item in menus.children"
          :key="item.id"
           @click="goPage(item)"
          >
          <span class="font-16" :class="item.id === currentId ? 'font-blue font-700' : 'font-balck'">{{item.title}}</span>
        </div>
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefreshHandle">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoadHandle"
      >
        <van-cell style="background-color: transparent; padding-bottom: 0"
          v-for="item in articles"
          :key="item.id"
          :to="'/article/detail/' + item.id"
          value-class="article-link"
          >
          <template>
            <div class="dis-flex">
              <div class="data-wrap flex-shrink0">
                <div class="dis-flex">
                  <span class="font-gray-black font-22">{{item.updated_at.split(' ')[0].split('-')[2]}}</span>
                </div>
                <div class="dis-flex flex-x-end">
                  <span class="font-gray-black font-14">{{item.updated_at.split(' ')[0].split('-')[1]}}月</span>
                </div>
              </div>
              <div class="title-wrap flex-basis100 van-multi-ellipsis--l2 font-black font-16">{{item.title}}</div>
            </div>
          </template>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  data() {
    return {
      articles: [],
      loading: false,
      finished: false,
      refreshing: false,
      cname: "",
      current: 0,
      last: 0,
      menus: null,
      currentId: null
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "ArticleList") {
      this.cname = to.params.cname;
      this.onRefreshHandle();
      this.init(to.query)
    }
    next();
  },
  methods: {
    async getarticlelist() {
      const resp = await this.$api.get("getarticlelist", {
        cname: this.cname,
        page: this.current + 1,
      });
      if (resp.success) {
        this.articles = this.articles.concat(resp.data.articles.data);
        this.last = resp.data.articles.last_page;
        this.current = resp.data.articles.current_page;
      }
      setTimeout(() => {
        if (this.last === this.current) {
          this.finished = true;
        }
        this.loading = false;
      }, 300);
    },
    async onLoadHandle() {
      await this.getarticlelist();
      //   this.loading = false
    },
    async onRefreshHandle() {
      this.current = 0;
      this.articles = [];
      this.finished = false;
      this.loading = true;
      this.onLoadHandle();
    },

    async getMenu (data) {
      const resp = await this.$api.get('getmenu', data)
      if (resp.success) {
        if (resp.data.menus.length > 0) {
          this.menus = resp.data.menus[0]
        }
      }
    },

    goPage (menu) {
      let query = {
        id: menu.id
      }
      if (menu.parent_id) {
        query.parent_id = menu.parent_id
      }
      this.$router.push({path: menu.link, query}).catch(() => {})
      this.showMenu = false
    },

    async init (query) {
      if (!query) return
      let id = query.id
      let parentId = query.parent_id
      if (id) {
        this.currentId = Number(id)
      }
      if (parentId) {
        await this.getMenu({id: parentId})
      }
    }
  },
  mounted() {
    this.cname = this.$route.params.cname;
    this.init(this.$route.query)
  },
};
</script>

<style lang="less" scoped>
.articlelist {
  background-color: #F0F0F0;
}

.article-link {
  width: 100%;

  .data-wrap {
    width: 48px;
    height: 48px;
    margin-right: 10px;
    position: relative;

    &:after {
      content: '';
      width: 30px;
      border-top: 1px solid #D2D2D2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-35deg);
    }
  }

  .title-wrap {
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
}
</style>
